import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CButton, CCol, CForm, CFormInput } from '@coreui/react';
import CompanyService from '../../../../../../services/CompanyService';

const DialogForm = ({ open, onClose, editItem, fetchData }) => {
  const [formData, setFormData] = useState(null);
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      await CompanyService.Create(formData);
      onClose();
      setFormData(null);
      fetchData();
    }
    setValidated(true);
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Vytvořit novou společnost</DialogTitle>
      <DialogContent>
        <CForm className="row g-3 needs-validation" noValidate validated={validated} onSubmit={handleSubmit}>
          <CCol md={12}>
            <CFormInput type="text" id="name" label="Název" value={editItem?.name} onChange={handleChange} required />
          </CCol>
          <DialogActions sx={{ p: '1.25rem' }}>
            <CButton color="light" onClick={onClose}>
              Zrušit
            </CButton>
            <CButton color="success" type="submit">
              Vytvořit
            </CButton>
          </DialogActions>
        </CForm>
      </DialogContent>
    </Dialog>
  );
};

export default DialogForm;
