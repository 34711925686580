import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePicker.scss';
import cs from 'date-fns/locale/cs';

export const CustomDatePicker = ({ label, id, onChange, required = false, value }) => {
  const [startDate, setStartDate] = useState(value != undefined ? new Date(value) : '');
  return (
    <div className="custom-date-picker">
      <label className="form-label">{label}</label>
      <div className="datePicker">
        <DatePicker
          id={id}
          className="form-control"
          selected={startDate}
          todayButton="Dnes"
          onChange={(date) => {
            setStartDate(date);
            onChange(date);
          }}
          locale={cs}
          dateFormat="dd.MM.yyyy"
          required={required}
        />
      </div>
    </div>
  );
};
