import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
//Import Material React Table Translations
import { MRT_Localization_CS } from 'material-react-table/locales/cs';
import { Button, Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DialogForm from './DialogForm';
import CompanyService from '../../../../../../services/CompanyService';

const CompanyGrid = () => {
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editItem, setEditItem] = useState();

  const fetchData = async () => {
    const response = await CompanyService.GetAll();
    setTableData(response.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    if (!confirm(`Opravdu chcete smazat záznam s ID: ${id}`)) {
      return;
    }
    await CompanyService.Delete(id);
    fetchData();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80
      },
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Název společnosti',
        size: 150
      }
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        localization={MRT_Localization_CS}
        columns={columns}
        data={tableData}
        enableColumnActions={true}
        enableRowActions={true}
        positionActionsColumn={'first'}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <Tooltip title="Upravit">
              <IconButton
                color="primary"
                onClick={async () => {
                  await CompanyService.GetById(row.original.id).then((response) => setEditItem(response.data));
                  setOpenDialogForm(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Smazat">
              <IconButton color="error" onClick={() => handleDelete(row.original.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="success" onClick={() => setOpenDialogForm(true)}>
            Vytvořit
          </Button>
        )}
      />
      <DialogForm
        open={openDialogForm}
        editItem={editItem}
        onClose={() => {
          setOpenDialogForm(false);
          setEditItem(null);
        }}
        fetchData={() => fetchData()}
      />
    </>
  );
};

export default CompanyGrid;
