import { Suspense, useEffect, useState, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = lazy(() => import('./components/layout/default/DefaultLayout'));
const AdminLayout = lazy(() => import('./components/layout/admin/AdminLayout'));

//App.tsx or similar
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { csCZ } from '@mui/material/locale';
import AuthService from './api/AuthService';
import EventTypes from './components/layout/admin/content/eventModul/eventTypes/EventTypeGrid';
import EventPersonGrid from './components/layout/admin/content/eventModul/eventPersons/EventPersonGrid';
import CompanyGrid from './components/layout/admin/content/eventModul/companies/CompanyGrid';
import EventGrid from './components/layout/admin/content/eventModul/events/EventGrid';

const App = () => {
  const theme = useTheme(); //replace with your theme/createTheme
  const [currentUser, setCurrentUser] = useState(undefined);
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setCurrentUser(user);
  }, []);

  const logOut = () => {
    AuthService.logout();
    setCurrentUser(undefined);
  };

  return (
    <ThemeProvider theme={createTheme(theme, csCZ)}>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Domů" element={<DefaultLayout currentUser={currentUser} logOut={logOut} />}>
              <Route path="prihlaseni"></Route>
              <Route path="registrace"></Route>
            </Route>

            {currentUser && (
              <Route
                path="/admin/*"
                name="Dashboard"
                element={<AdminLayout currentUser={currentUser} logOut={logOut} />}
              >
                <Route path="udalosti/"></Route>
                <Route path="udalosti/prehled" element={<EventGrid />}></Route>
                <Route path="udalosti/typy-udalosti" element={<EventTypes />}></Route>
                <Route path="udalosti/osoby-udalosti" element={<EventPersonGrid />}></Route>
                <Route path="udalosti/periody-udalosti" element={<EventTypes />}></Route>
                <Route path="udalosti/spolecnosti" element={<CompanyGrid />}></Route>
              </Route>
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
