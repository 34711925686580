import { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CButton, CCol, CForm, CFormInput, CFormSelect, CFormTextarea } from '@coreui/react';
import EventService from '../../../../../../services/EventService';
import EventTypeService from '../../../../../../services/EventTypeService';
import EventPersonService from '../../../../../../services/EventPersonService';
import CompanyService from '../../../../../../services/CompanyService';
import { CustomDatePicker } from '../../../../../other/form/CustomDatePicker';

const DialogForm = ({ open, onClose, editItem, fetchData }) => {
  const [formData, setFormData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [eventTypes, setEventTypes] = useState();
  const [eventPersons, setEventPersons] = useState();
  const [companies, setCompanies] = useState();

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      await EventService.Create(formData);
      onClose();
      setFormData(null);
      fetchData();
    }
    setValidated(true);
  };

  const fetchEventTypes = async () => {
    const response = await EventTypeService.GetAll();
    const transformedEventTypes = response.data.data.map((eventType) => ({
      label: eventType.name,
      value: eventType.id
    }));

    setEventTypes(transformedEventTypes);
  };

  const fetchEventPersons = async () => {
    const response = await EventPersonService.GetAll();
    const transformedEventPersons = response.data.data.map((eventPerson) => ({
      label: `${eventPerson.firstName} ${eventPerson.lastName}`,
      value: eventPerson.id
    }));

    setEventPersons(transformedEventPersons);
  };

  const fetchCompanies = async () => {
    const response = await CompanyService.GetAll();
    const transformedEventPersons = response.data.data.map((company) => ({
      label: company.name,
      value: company.id
    }));

    setCompanies(transformedEventPersons);
  };

  useEffect(() => {
    fetchEventTypes();
    fetchEventPersons();
    fetchCompanies();
  }, []);

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Přidat událost</DialogTitle>
      <DialogContent>
        <CForm className="row g-3 needs-validation" noValidate validated={validated} onSubmit={handleSubmit}>
          <CCol md={12}>
            <CFormInput type="text" id="name" label="Název" value={editItem?.name} onChange={handleChange} required />
          </CCol>
          <CCol md={12}>
            <CFormSelect
              id="eventTypeId"
              label="Typy události"
              aria-describedby="eventTypeIdFeedback"
              feedbackInvalid="Please select a valid state."
              onChange={handleChange}
              required
              tooltipFeedback
              value={editItem?.eventTypeId}
            >
              <option selected="" disabled="" value="">
                Vyber typ
              </option>
              {eventTypes?.map((eventType) => (
                <option key={eventType.value} value={eventType.value}>
                  {eventType.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
          <CCol md={12}>
            <CFormSelect
              id="eventPersonId"
              label="Osoby události"
              aria-describedby="eventTypeIdFeedback"
              feedbackInvalid="Please select a valid state."
              onChange={handleChange}
              required
              tooltipFeedback
              value={editItem?.eventPersonId}
            >
              <option selected="" disabled="" value="">
                Vyber osobu
              </option>
              {eventPersons?.map((eventPerson) => (
                <option key={eventPerson.value} value={eventPerson.value}>
                  {eventPerson.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
          <CCol md={12}>
            <CFormSelect
              id="companyId"
              label="Společnosti"
              aria-describedby="eventTypeIdFeedback"
              feedbackInvalid="Please select a valid state."
              onChange={handleChange}
              tooltipFeedback
              value={editItem?.companyId}
            >
              <option selected="" disabled="" value="">
                Vyber společnost
              </option>
              {companies?.map((company) => (
                <option key={company.value} value={company.value}>
                  {company.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
          <CCol md={4}>
            <CustomDatePicker
              id="created"
              label="Vytvořeno"
              onChange={(e) => handleChange({ target: { id: 'created', value: e } })}
              required={true}
              value={editItem?.created}
            />
          </CCol>
          <CCol md={4}>
            <CustomDatePicker
              id="validFrom"
              label="Platné od"
              required={true}
              onChange={(e) => handleChange({ target: { id: 'validFrom', value: e } })}
              value={editItem?.validFrom}
            />
          </CCol>
          <CCol md={4}>
            <CustomDatePicker
              id="expiration"
              label="Platné do"
              required={true}
              onChange={(e) => handleChange({ target: { id: 'expiration', value: e } })}
              value={editItem?.expiration}
            />
          </CCol>
          <CCol md={12}>
            <CFormTextarea id="note" label="Poznámka" value={editItem?.note} onChange={handleChange} />
          </CCol>
          <DialogActions sx={{ p: '1.25rem' }}>
            <CButton color="light" onClick={onClose}>
              Zrušit
            </CButton>
            <CButton color="success" type="submit">
              Vytvořit
            </CButton>
          </DialogActions>
        </CForm>
      </DialogContent>
    </Dialog>
  );
};

export default DialogForm;
